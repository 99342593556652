
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import BigLoader from "@/components/svg/BigLoader.vue";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $metrika: any;
  }
}

export default defineComponent({
  components: { BigLoader },
  data() {
    return {
      params: {} as URLSearchParams,
    };
  },
  async mounted() {
    this.params = new URLSearchParams(window.location.search);
    await this.authorizeUser();
  },
  methods: {
    ...mapActions({
      authLogin: "auth/login",
      authRegister: "auth/register",
    }),

    async authorizeUser() {
      const code = this.params.get("code") || "";
      const token = this.params.get("token") || "";

      if (!token && !code) {
        this.$router.replace("/");
        return;
      }

      if (code) {
        await this.authLogin({ code });
        this.$metrika.reachGoal("ai-user-logged-in");
        return;
      }

      if (token) {
        await this.authRegister({ token });
        this.$metrika.reachGoal("ai-user-registered");
        return;
      }
    },
  },
});
