<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.681793"
      y="0.0989151"
      width="36.5584"
      height="37.429"
      rx="18.2792"
      fill="#FF5B5B"
      fill-opacity="0.15"
    />
    <path
      d="M28.8086 10.8403C27.5316 9.62794 25.8388 8.88802 24.0361 8.75413C22.2334 8.62025 20.4395 9.10124 18.9785 10.1102C17.4458 9.03071 15.538 8.54121 13.6394 8.74029C11.7407 8.93937 9.99226 9.81225 8.74607 11.1831C7.49989 12.554 6.84855 14.3211 6.92321 16.1285C6.99788 17.9359 7.793 19.6494 9.14846 20.924L16.6294 28.019C17.2559 28.6028 18.0996 28.93 18.9785 28.93C19.8575 28.93 20.7012 28.6028 21.3276 28.019L28.8086 20.924C30.2151 19.584 31.0046 17.7714 31.0046 15.8821C31.0046 13.9928 30.2151 12.1803 28.8086 10.8403Z"
      fill="#FF2121"
    />
  </svg>
</template>
