import { Ref, onBeforeUnmount, onMounted } from "vue";

export default function useDetectOutsideClick(
  component: Ref,
  callback: object,
) {
  if (!component) return;
  const listener = (event: Event) => {
    if (
      event.target !== component.value &&
      event.composedPath().includes(component.value)
    ) {
      return;
    }
    if (
      typeof callback === "function" &&
      !(event.target as HTMLElement).classList.contains(
        "click-outside-exception",
      )
    ) {
      callback();
    }
  };
  onMounted(() => {
    window.addEventListener("click", listener);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("click", listener);
  });

  return { listener };
}
