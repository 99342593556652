import { Axios } from "axios";

import { GetCategoriesByInterestRequest } from "./dtos/request/get-categories-by-interest.request.dto";
import { GetCategoriesByInterestResponse } from "./dtos/response/get-categories-by-interest.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async getCategoriesByInterest(
    payload: GetCategoriesByInterestRequest,
  ): Promise<GetCategoriesByInterestResponse> {
    const res = await $axios.get(
      `${apiUrl}/interests/${payload.interestId}/categories`,
    );

    return res.data;
  },
});
