<template>
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9488 0.619141C21.6273 0.619141 16.4254 2.19714 12.0007 5.15359C7.57609 8.11004 4.12751 12.3122 2.09107 17.2286C0.0546266 22.145 -0.478199 27.5548 0.559969 32.774C1.59814 37.9933 4.16067 42.7874 7.92352 46.5503C11.6864 50.3131 16.4805 52.8757 21.6998 53.9138C26.919 54.952 32.3288 54.4192 37.2452 52.3827C42.1616 50.3463 46.3638 46.8977 49.3202 42.4731C52.2767 38.0484 53.8547 32.8465 53.8547 27.525C53.8547 23.9917 53.1587 20.4929 51.8066 17.2286C50.4544 13.9642 48.4726 10.9981 45.9741 8.49968C43.4757 6.00124 40.5096 4.01937 37.2452 2.66723C33.9809 1.31508 30.4821 0.619141 26.9488 0.619141ZM26.9488 49.0496C22.6916 49.0496 18.5301 47.7872 14.9904 45.4221C11.4506 43.0569 8.69177 39.6952 7.06262 35.7621C5.43347 31.829 5.00721 27.5011 5.83774 23.3257C6.66827 19.1503 8.7183 15.315 11.7286 12.3047C14.7389 9.29446 18.5742 7.24443 22.7496 6.4139C26.9249 5.58336 31.2528 6.00963 35.186 7.63878C39.1191 9.26793 42.4808 12.0268 44.8459 15.5665C47.2111 19.1062 48.4735 23.2678 48.4735 27.525C48.4735 33.2337 46.2057 38.7086 42.1691 42.7452C38.1324 46.7819 32.6575 49.0496 26.9488 49.0496Z"
      fill="#02B1BA"
    />
    <path
      d="M26.9488 0.619141C21.6273 0.619141 16.4254 2.19714 12.0007 5.15359C7.57609 8.11004 4.12751 12.3122 2.09107 17.2286C0.0546266 22.145 -0.478199 27.5548 0.559969 32.774C1.59814 37.9933 4.16067 42.7874 7.92352 46.5503C11.6864 50.3131 16.4805 52.8757 21.6998 53.9138C26.919 54.952 32.3288 54.4192 37.2452 52.3827C42.1616 50.3463 46.3638 46.8977 49.3202 42.4731C52.2767 38.0484 53.8547 32.8465 53.8547 27.525C53.8547 23.9917 53.1587 20.4929 51.8066 17.2286C50.4544 13.9642 48.4726 10.9981 45.9741 8.49968C43.4757 6.00124 40.5096 4.01937 37.2452 2.66723C33.9809 1.31508 30.4821 0.619141 26.9488 0.619141ZM26.9488 49.0496C22.6916 49.0496 18.5301 47.7872 14.9904 45.4221C11.4506 43.0569 8.69177 39.6952 7.06262 35.7621C5.43347 31.829 5.00721 27.5011 5.83774 23.3257C6.66827 19.1503 8.7183 15.315 11.7286 12.3047C14.7389 9.29446 18.5742 7.24443 22.7496 6.4139C26.9249 5.58336 31.2528 6.00963 35.186 7.63878C39.1191 9.26793 42.4808 12.0268 44.8459 15.5665C47.2111 19.1062 48.4735 23.2678 48.4735 27.525C48.4735 33.2337 46.2057 38.7086 42.1691 42.7452C38.1324 46.7819 32.6575 49.0496 26.9488 49.0496Z"
      fill="#02B1BA"
    />
    <path
      d="M26.9494 19.4532C28.4353 19.4532 29.64 18.2486 29.64 16.7626C29.64 15.2766 28.4353 14.072 26.9494 14.072C25.4634 14.072 24.2588 15.2766 24.2588 16.7626C24.2588 18.2486 25.4634 19.4532 26.9494 19.4532Z"
      fill="#02B1BA"
    />
    <path
      d="M26.9494 19.4532C28.4353 19.4532 29.64 18.2486 29.64 16.7626C29.64 15.2766 28.4353 14.072 26.9494 14.072C25.4634 14.072 24.2588 15.2766 24.2588 16.7626C24.2588 18.2486 25.4634 19.4532 26.9494 19.4532Z"
      fill="#02B1BA"
    />
    <path
      d="M26.9494 22.1438C26.2358 22.1438 25.5514 22.4273 25.0468 22.9319C24.5423 23.4364 24.2588 24.1208 24.2588 24.8344V38.2873C24.2588 39.0009 24.5423 39.6853 25.0468 40.1898C25.5514 40.6944 26.2358 40.9779 26.9494 40.9779C27.663 40.9779 28.3473 40.6944 28.8519 40.1898C29.3565 39.6853 29.64 39.0009 29.64 38.2873V24.8344C29.64 24.1208 29.3565 23.4364 28.8519 22.9319C28.3473 22.4273 27.663 22.1438 26.9494 22.1438Z"
      fill="#02B1BA"
    />
    <path
      d="M26.9494 22.1438C26.2358 22.1438 25.5514 22.4273 25.0468 22.9319C24.5423 23.4364 24.2588 24.1208 24.2588 24.8344V38.2873C24.2588 39.0009 24.5423 39.6853 25.0468 40.1898C25.5514 40.6944 26.2358 40.9779 26.9494 40.9779C27.663 40.9779 28.3473 40.6944 28.8519 40.1898C29.3565 39.6853 29.64 39.0009 29.64 38.2873V24.8344C29.64 24.1208 29.3565 23.4364 28.8519 22.9319C28.3473 22.4273 27.663 22.1438 26.9494 22.1438Z"
      fill="#02B1BA"
    />
  </svg>
</template>
