import axios from "axios";
import type { App } from "vue";

// Interceptor, which adds auth header for each request.
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3033"
      : "https://api.ai.constructorium.ru",
});

export default {
  install: (app: App) => {
    app.config.globalProperties.$axios = axiosInstance;
  },
};
