import Hammer from "hammerjs";

export const initCards = () => {
  const tinderContainer = document.querySelector(".tinder");
  const allCards = <NodeListOf<HTMLElement>>(
    document.querySelectorAll(".tinder--card")
  );

  const newCards = <NodeListOf<HTMLElement>>(
    document.querySelectorAll(".tinder--card:not(.removed.initialized)")
  );

  newCards.forEach((card, index) => {
    card.style.zIndex = `${allCards.length - index}`;
    card.style.transform = "scale(" + (20 - index) / 20 + ")";
    card.style.filter = "blur(" + (0 + index) / 3 + "rem)";

    if (index === 0) {
      tinderContainer?.setAttribute("current-card", card.id);
    }
  });

  tinderContainer?.classList.add("loaded");
};

export const addHammerListeners = () => {
  const tinderContainer = document.querySelector(".tinder");
  const allCards = <NodeListOf<HTMLElement>>(
    document.querySelectorAll(".tinder--card:not(.initialized)")
  );

  initCards();

  allCards.forEach((el) => {
    if (el.id === "last_card") {
      return;
    }

    el.classList.add("initialized");

    const hammertime = new Hammer(el);

    hammertime.on("pan", (event: HammerInput) => {
      el.classList.add("moving");

      if (event.deltaX === 0) return;
      if (event.center.x === 0 && event.center.y === 0) return;

      tinderContainer?.classList.toggle("tinder_love", event.deltaX > 0);
      tinderContainer?.classList.toggle("tinder_nope", event.deltaX < 0);

      const xMulti = event.deltaX * 0.03;
      const yMulti = event.deltaY / 80;
      const rotate = xMulti * yMulti;

      event.target.style.transform =
        "translate(" +
        event.deltaX +
        "px, " +
        event.deltaY +
        "px) rotate(" +
        rotate +
        "deg)";
    });

    hammertime.on("panend", (event: HammerInput) => {
      el.classList.remove("moving");
      tinderContainer?.classList.remove("tinder_love");
      tinderContainer?.classList.remove("tinder_nope");

      const moveOutWidth = document.body.clientWidth;
      const keep =
        Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

      event.target.classList.toggle("removed", !keep);

      if (keep) {
        event.target.style.transform = "";
      } else {
        const endX = Math.max(
          Math.abs(event.velocityX) * moveOutWidth,
          moveOutWidth,
        );
        const toX = event.deltaX > 0 ? endX : -endX;
        const endY = Math.abs(event.velocityY) * moveOutWidth;
        const toY = event.deltaY > 0 ? endY : -endY;
        const xMulti = event.deltaX * 0.03;
        const yMulti = event.deltaY / 80;
        const rotate = xMulti * yMulti;

        event.target.style.transform =
          "translate(" +
          toX +
          "px, " +
          (toY + event.deltaY) +
          "px) rotate(" +
          rotate +
          "deg)";
        initCards();
      }
    });
  });
};
