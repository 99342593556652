export const forbiddenWords = [
  "Сука",
  "Блять",
  "Блядь",
  "Пизда",
  "Писда",
  "Нахуй",
  "На хуй",
  "Ебать",
  "Долбаёб",
  "Пошел на хуй",
  "Иди на хуй",
  "Херня",
  "Хуйня",
  "Гей",
  "Секс",
  "Гей-порно",
  "Порно",
  "Хуй",
  "Пидр",
  "Пидарас",
  "Пидорас",
  "Иди в жопу",
  "Хентай",
  "Война",
  "Военная операция",
  "Бандеровцы",
  "Бандера",
  "Украина",
  "Майдан",
  "Взрыв",
  "ЛНР",
  "ДНР",
  "Специальная военная операция",
  "Армия",
  "Путин",
  "Зеленский",
  "Байден",
  "Русский военный корабль",
  "Политика",
  "США",
  "Массовые беспорядки",
  "Власть",
  "Митинг",
  "Навальный",
  "Тюрьма",
  "Убийства",
  "Убийство",
  "Убийца",
  "Призыв",
  "топлесс",
  "топлес",
  "порно",
  "порнушка",
  "порнуха",
  "порнуху",
  "порнушку",
  "порнухой",
  "порнушкой",
  "трах",
  "трахаться",
  "трахатся",
  "ебля",
  "ебли",
  "ебуться",
  "ебаться",
  "ебут",
  "ебать",
  "половой",
  "половой акт",
  "сношение",
  "сношаются",
  "секс",
  "сексом",
  "сексуально",
  "сексуальная",
  "сексуальный",
  "сексуальность",
  "эротика",
  "эротическая",
  "эротический",
  "эротические",
  "эротическими",
  "нудисты",
  "нудист",
  "нудистами",
  "нудистов",
  "нудистам",
  "naked",
  "6лядь",
  "6лять",
  "abortion",
  "adult",
  "amateur",
  "anal",
  "analingus",
  "aneurysm",
  "anus",
  "arousal",
  "arse",
  "asslicking",
  "attack",
  "b3ъeб",
  "bdsm",
  "bisex",
  "bisexual",
  "bitch",
  "blowjob",
  "bondage",
  "bukkаkе",
  "burial",
  "camgirls",
  "cancer",
  "casino",
  "casket",
  "cekc",
  "cekс",
  "cerebral",
  "ceкc",
  "ceкс",
  "children",
  "clitoris",
  "cocaine",
  "bondage",
  "cock",
  "cocksucker",
  "condom",
  "creampie",
  "cumshot",
  "cumswap",
  "cunninglus",
  "cunt",
  "cеkc",
  "cеkс",
  "cекc",
  "cекс",
  "deceased",
  "deepthroat",
  "demise",
  "desanguinated",
  "dies",
  "dildo",
  "disfigured",
  "doggystyle",
  "dosug",
  "drown",
  "drowned",
  "drugs",
  "dying",
  "ebal",
  "eblan",
  "electrocuted",
  "electrocution",
  "embolism",
  "erection",
  "erotic",
  "erotico",
  "escorts",
  "eskort",
  "etoile",
  "execution",
  "exhibitionism",
  "exhibitionist",
  "expire",
  "eбaл",
  "eбaть",
  "eбать",
  "eблантий",
  "eбёт",
  "facial",
  "fellatio",
  "fetish",
  "fuck",
  "funeral",
  "gambling",
  "gangbang",
  "gay",
  "gays",
  "generic",
  "gjhyj",
  "gonzo",
  "groupsex",
  "hairy",
  "handjob",
  "hardcore",
  "hedonist",
  "hemorrhage",
  "heroin",
  "homosexual",
  "horror",
  "incarcerated",
  "jail",
  "johnson",
  "juego",
  "kill",
  "killed",
  "killer",
  "latex",
  "lesbi",
  "lesbian",
  "lesbians",
  "lesbo",
  "levaquin",
  "levitra",
  "licking",
  "livesex",
  "maimed",
  "manslaughter",
  "marijuana",
  "masochism",
  "masturbation",
  "mature",
  "medication",
  "miscarriage",
  "morphine",
  "murder",
  "murdered",
  "murderer",
  "naked",
  "nude",
  "orgy",
  "overdose",
  "oxycodone",
  "oxycontin",
  "panties",
  "pantyhose",
  "paralyzed",
  "pedophile",
  "pedophilia",
  "penetration",
  "penis",
  "penthouse",
  "perished",
  "pharmaceutical",
  "pharmacy",
  "pissing",
  "planker",
  "playboy",
  "plonker",
  "poisoned",
  "poisoning",
  "poker",
  "porn",
  "porno",
  "pornographic",
  "pornstars",
  "prison",
  "prostitutk",
  "pussy",
  "sadism",
  "sadomasochism",
  "schlong",
  "schoolgirl",
  "sdilong",
  "sex",
  "sexual",
  "sexuality",
  "sexually",
  "навальный",
  "путин",
  "украин",
  "хохл",
  "москали",
  "смерть",
  "мастурб",
  "Дрочк",
];

export const unclearForbiddenWords = [
  "Хер",
  "Сво",
  "Танк",
  "ню",
  "nu",
  "6ля",
  "ass",
  "pom",
  "pov",
];
