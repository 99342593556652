import { App } from "vue";

import createUserRepository from "@/api/user/user.repository";
import { UserApiKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createUserRepository(globalProperties.$axios);
    app.provide(UserApiKey, repoWithAxios(globalProperties.$apiUrl));
  },
};
