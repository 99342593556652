export class LikesCacheManager {
  private storage;
  private storageKey = "likes";

  constructor(storage: Storage) {
    this.storage = storage;
  }

  handleLikeIdea(ideaId: string) {
    const cache = this.getCacheData();
    let updatedCache = [];

    const likeExist = cache.find((id) => id === ideaId);

    if (likeExist) {
      updatedCache = cache.filter((id) => id !== ideaId);
    } else {
      updatedCache = [...cache, ideaId];
    }

    this.saveCacheData(updatedCache);
  }

  getIdeaState(ideaId: string) {
    const cache = this.getCacheData();

    const ideaExists = cache.find((id) => id === ideaId);

    return !!ideaExists;
  }

  getCacheData() {
    try {
      let storageData = this.storage.getItem(this.storageKey);
      storageData = storageData ? JSON.parse(storageData) : null;

      let cache: Array<string> = [];
      if (Array.isArray(storageData)) {
        cache = storageData;
      }

      return cache;
    } catch {
      return [];
    }
  }

  private saveCacheData(data: Array<string>) {
    this.storage.setItem(this.storageKey, JSON.stringify(data));
  }
}
