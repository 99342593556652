<template>
  <svg
    width="386"
    height="386"
    viewBox="0 0 386 386"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M191.602 337.077C115.916 336.548 54.2802 278.809 48.6988 204.814C48.1936 198.201 42.9009 192.791 36.2369 192.791L12.1792 192.791C5.53925 192.791 0.0540905 198.177 0.439014 204.79C5.75577 301.414 82.5721 378.174 179.236 384.715C185.852 385.172 196.678 385.22 203.294 384.884C304.047 379.377 384.352 296.34 385.098 194.113C385.795 91.8865 305.731 7.76763 205.05 0.841879C198.434 0.385001 193.093 5.77166 193.189 12.4088L193.502 36.4565C193.598 43.0937 199.059 48.4563 205.675 49.0575C279.725 55.6706 337.512 118.123 337.006 193.753C336.405 273.471 271.329 337.63 191.602 337.077Z"
      fill="#0DDFD3"
    />
  </svg>
</template>
