import { Axios } from "axios";
import { LoginUserRequest } from "./dtos/request/login-user.request.dto";
import { AuthUserResponse } from "./dtos/response/auth-user.response.dto";
import { GetUserResponse } from "./dtos/response/get-user.response.dto";
import { LoginUserResponse } from "./dtos/response/login-user.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async authUser(payload: string): Promise<AuthUserResponse> {
    const token = localStorage.getItem("token");

    const res = await $axios.post(`${apiUrl}/users/auth`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res.data;
  },

  async getUser(): Promise<GetUserResponse> {
    const token = localStorage.getItem("token");

    const res = await $axios.get(`${apiUrl}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res.data;
  },

  async loginUser(payload: LoginUserRequest): Promise<LoginUserResponse> {
    const res = await $axios.post(`${apiUrl}/users/login`, payload);

    return res.data;
  },
});
