<template>
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8982 2.59204C22.5499 1.31195 20.7625 0.530648 18.859 0.38928C16.9554 0.247912 15.0613 0.755794 13.5186 1.82118C11.9002 0.681324 9.88572 0.164456 7.88094 0.374667C5.87615 0.584877 4.02994 1.50655 2.71408 2.95409C1.39822 4.40162 0.710465 6.26749 0.789303 8.17596C0.868141 10.0844 1.70772 11.8937 3.13897 13.2395L11.0382 20.7313C11.6996 21.3477 12.5905 21.6932 13.5186 21.6932C14.4467 21.6932 15.3376 21.3477 15.999 20.7313L23.8982 13.2395C25.3834 11.8246 26.217 9.9107 26.217 7.91578C26.217 5.92085 25.3834 4.00697 23.8982 2.59204ZM22.1047 11.5773L14.2055 19.0571C14.1156 19.143 14.0086 19.2112 13.8907 19.2578C13.7728 19.3044 13.6463 19.3283 13.5186 19.3283C13.3909 19.3283 13.2644 19.3044 13.1465 19.2578C13.0286 19.2112 12.9216 19.143 12.8317 19.0571L4.93251 11.5412C3.93494 10.5756 3.37634 9.27859 3.37634 7.92782C3.37634 6.57705 3.93494 5.27999 4.93251 4.31443C5.94905 3.36409 7.32004 2.83121 8.74855 2.83121C10.1771 2.83121 11.5481 3.36409 12.5646 4.31443C12.6828 4.42732 12.8235 4.51692 12.9785 4.57807C13.1335 4.63922 13.2998 4.6707 13.4677 4.6707C13.6356 4.6707 13.8019 4.63922 13.9569 4.57807C14.1119 4.51692 14.2526 4.42732 14.3709 4.31443C15.3874 3.36409 16.7584 2.83121 18.1869 2.83121C19.6154 2.83121 20.9864 3.36409 22.0029 4.31443C23.0142 5.26734 23.5912 6.55737 23.6102 7.90817C23.6292 9.25897 23.0888 10.5631 22.1047 11.5412V11.5773Z"
      fill="currentColor"
    />
  </svg>
</template>
