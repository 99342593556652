<template>
  <svg
    width="977"
    height="721"
    viewBox="0 0 977 721"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9.5V83H63L121.5 141.5V194.75M121.5 194.75V248L15 354.5V561L174.5 720.5H411.5L477.5 652.5H772.5L968.5 447.5V9.5M121.5 194.75L239.5 76.75H309.5V9.5"
      stroke="#02B1BA"
      stroke-opacity="0.5"
    />
    <circle cx="309.423" cy="9.42319" r="7.97347" fill="#FDFDFD" />
    <circle
      cx="309.423"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle
      cx="309.423"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle cx="967.423" cy="9.42319" r="7.97347" fill="#FDFDFD" />
    <circle
      cx="967.423"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle
      cx="967.423"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle cx="9.42319" cy="9.42319" r="7.97347" fill="#FDFDFD" />
    <circle
      cx="9.42319"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle
      cx="9.42319"
      cy="9.42319"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle cx="477.423" cy="652.423" r="7.97347" fill="white" />
    <circle
      cx="477.423"
      cy="652.423"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
    <circle
      cx="477.423"
      cy="652.423"
      r="7.97347"
      stroke="#02B1BA"
      stroke-width="2.89944"
    />
  </svg>
</template>
