<template>
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M12.4445 2.00023L2.00001 11.2013L12.4445 20.4023"
        stroke="currentColor"
        stroke-width="3.00878"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4445 2.00023L2.00001 11.2013L12.4445 20.4023"
        stroke="currentColor"
        stroke-width="3.00878"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
