<template>
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1076 44.7135H11.1049C5.05413 44.7135 0.103516 39.7629 0.103516 33.7121V11.7094C0.103516 5.65863 5.05413 0.708008 11.1049 0.708008H33.1076C39.1584 0.708008 44.109 5.65863 44.109 11.7094V33.7121C44.109 39.7629 39.1584 44.7135 33.1076 44.7135Z"
      fill="#2795D2"
    />
    <path
      d="M29.6207 34.9949C29.0706 34.8116 28.3372 34.6282 27.9705 34.2615C25.4035 32.6113 22.8365 30.7777 20.4528 29.1275C20.2695 28.9442 20.0861 28.7608 19.9028 28.5774C19.5361 28.0274 19.5361 27.6607 19.9028 27.1106C20.0861 26.7439 20.4529 26.3772 20.6362 26.1938C22.6531 24.3602 24.67 22.3433 26.5036 20.5098C27.0537 20.1431 27.4204 19.593 27.7871 19.0429C27.9704 18.8596 27.9704 18.4928 27.7871 18.4928C27.6037 18.3095 27.4204 18.3095 27.237 18.3095C26.8703 18.4929 26.5036 18.6762 26.3202 18.8596C23.2032 20.8765 20.2695 22.8934 17.1524 24.9103C15.6856 25.8271 14.2187 26.1938 12.5685 25.6437C11.4684 25.277 10.1849 24.9103 9.08476 24.5436C8.9014 24.5436 8.90141 24.5436 8.71805 24.3602C8.35134 24.1769 7.80127 23.9935 7.80127 23.4435C7.80127 22.8934 8.16797 22.71 8.53468 22.5267C9.63482 21.9766 10.735 21.6099 11.8351 21.0598C18.0692 18.4929 24.3033 15.7425 30.5374 13.1755C31.2709 12.8088 32.0043 12.6255 32.7377 12.4421C34.0212 12.2587 34.5713 12.8088 34.5713 14.0923C34.3879 15.5592 34.2046 17.2094 34.0212 18.6762C33.4711 21.7933 33.1044 24.9103 32.5544 27.844C32.371 29.3109 32.0043 30.9611 31.6376 32.4279C31.6376 32.7946 31.2709 33.3447 31.0875 33.7114C30.7208 34.6282 30.3541 34.9949 29.6207 34.9949Z"
      fill="white"
    />
  </svg>
</template>
