<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="11" cy="10.7179" rx="11" ry="10.7179" fill="#007B82" />
    <path
      d="M7.27634 18.1635V3.52295H11.9493C13.8348 3.52295 15.208 3.90571 16.0688 4.67123C16.9433 5.43675 17.3805 6.48934 17.3805 7.829C17.3805 8.62186 17.2029 9.36687 16.8476 10.064C16.4924 10.7612 15.898 11.3217 15.0645 11.7455C14.2447 12.1692 13.1243 12.3811 11.7033 12.3811H10.3711V18.1635H7.27634ZM5.63672 15.9695V13.9805H13.5274V15.9695H5.63672ZM5.63672 12.3811V9.83849H11.6418V12.3811H5.63672ZM11.3959 9.83849C11.9834 9.83849 12.489 9.77697 12.9125 9.65394C13.3361 9.51724 13.664 9.30536 13.8963 9.01829C14.1286 8.73122 14.2447 8.36896 14.2447 7.93152C14.2447 7.3027 14.0466 6.83792 13.6504 6.53718C13.2541 6.22277 12.6324 6.06557 11.7853 6.06557H10.3711V9.83849H11.3959Z"
      fill="white"
    />
  </svg>
</template>
