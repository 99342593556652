import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProfileView from "../views/ProfileView.vue";
import LoginView from "../views/LoginView.vue";
import NotFoundView from "../views/NotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/generator",
    name: "generator",
    component: ProfileView,
    beforeEnter: () => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Эта страница доступна только авторизованным пользователям");
        return "/";
      }
    },
  },
  {
    path: "/login-success",
    name: "login-success",
    component: LoginView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
