<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1318 29.7163C34.3047 30.8904 34.3047 32.7168 33.1318 33.8909C32.5453 34.4779 31.8285 34.7388 31.0465 34.7388C30.2645 34.7388 29.5477 34.4779 28.9612 33.8909L17.036 21.9542L5.11083 33.8909C4.52435 34.4779 3.80753 34.7388 3.02555 34.7388C2.24357 34.7388 1.52676 34.4779 0.940274 33.8909C-0.232695 32.7168 -0.232695 30.8904 0.940274 29.7163L12.8655 17.7797L0.940274 5.84304C-0.232695 4.66894 -0.232695 2.84257 0.940274 1.66847C2.11324 0.494379 3.93786 0.494379 5.11083 1.66847L17.036 13.6051L28.9612 1.66847C30.1342 0.494379 31.9588 0.494379 33.1318 1.66847C34.3047 2.84257 34.3047 4.66894 33.1318 5.84304L21.2066 17.7797L33.1318 29.7163Z"
      fill="currentColor"
    />
  </svg>
</template>
