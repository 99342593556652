<template>
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M1.88868 20.4021L12.3331 11.2011L1.88867 2"
        stroke="currentColor"
        stroke-width="3.00878"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.88868 20.4021L12.3331 11.2011L1.88867 2"
        stroke="currentColor"
        stroke-width="3.00878"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
