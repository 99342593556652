<template>
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5 57.5C18.6929 57.5 7.5 46.3071 7.5 32.5C7.5 18.6929 18.6929 7.5 32.5 7.5C46.3071 7.5 57.5 18.6929 57.5 32.5C57.5 46.3071 46.3071 57.5 32.5 57.5Z"
      stroke="#02B1BA"
      stroke-width="5"
    />
    <path
      d="M45 24L28.3333 41L20 32.5004"
      stroke="#02B1BA"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
