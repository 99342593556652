import { AuthModuleState as State } from "@/types";
import { Commit } from "vuex";
import { axiosInstance } from "@/plugins/axios";
import { User } from "@/api/common/interfaces";
import router from "../router";

const initialState = { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,

  getters: {
    user(state: State) {
      return state.user;
    },
    isAuthenticated(state: State) {
      return state.status.loggedIn;
    },
  },

  actions: {
    async verifyUser({ commit }: { commit: Commit }) {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const res = await axiosInstance.get(`/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const user = res.data.user;
        commit("loginSuccess", { user });
      } catch (e) {
        console.log(e);
        localStorage.removeItem("token");
        commit("loginFailure");
      }
    },

    async login({ commit }: { commit: Commit }, { code }: { code: string }) {
      try {
        const payload = { code };
        const { data } = await axiosInstance.post(`/users/login`, payload);

        localStorage.setItem("token", data?.access_token);
        commit("loginSuccess", { user: data.user });
        router.replace("/generator");
      } catch (e) {
        console.log(e);
        commit("loginFailure");
        router.replace("/");
      }
    },

    async register(
      { commit }: { commit: Commit },
      { token }: { token: string },
    ) {
      try {
        const { data } = await axiosInstance.post(
          `/users/register`,
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        );

        localStorage.setItem("token", data?.access_token);
        commit("loginSuccess", { user: data.user });
        router.replace("/generator");
      } catch (e) {
        console.log(e);
        commit("loginFailure");
        router.replace("/");
      }
    },

    async getLatestUserData({ commit }: { commit: Commit }) {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const res = await axiosInstance.get(`/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const user = res.data.user;
        commit("updateUser", { user });
      } catch (e) {
        console.log(e);
        localStorage.removeItem("token");
        commit("loginFailure");
      }
    },

    logout({ commit }: { commit: Commit }) {
      localStorage.removeItem("token");
      commit("logout");
    },
  },

  mutations: {
    loginSuccess(state: State, { user }: { user: User }) {
      state.status.loggedIn = true;
      state.user = user;
    },

    loginFailure(state: State) {
      state.status.loggedIn = false;
      state.user = null;
    },

    logout(state: State) {
      state.status.loggedIn = false;
      state.user = null;
    },

    updateUser(state: State, { user }: { user: User }) {
      state.user = user;
    },
  },
};
