<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="10.8421"
      cy="10.5641"
      rx="10.8421"
      ry="10.5641"
      fill="#007B82"
    />
    <path
      d="M11.6763 9.79982C11.7458 9.86932 11.8153 9.86932 11.9543 9.86932H16.8194C17.2364 9.86932 17.5144 9.59132 17.5144 9.17431C17.5144 9.03531 17.5144 8.96581 17.4449 8.89631L15.0124 4.03126C14.8039 3.68376 14.3869 3.54476 14.0394 3.75326C13.9699 3.82276 13.8309 3.89226 13.7614 4.03126L11.3288 8.89631C11.1898 9.17431 11.3288 9.59132 11.6763 9.79982ZM14.3869 5.83828L15.7074 8.47931H13.0664L14.3869 5.83828ZM6.74179 3.61426C5.00427 3.61426 3.61426 5.00427 3.61426 6.74179C3.61426 8.47931 5.00427 9.86932 6.74179 9.86932C8.47931 9.86932 9.86932 8.47931 9.86932 6.74179C9.86932 5.00427 8.47931 3.61426 6.74179 3.61426ZM6.74179 8.47931C5.76878 8.47931 5.00427 7.7148 5.00427 6.74179C5.00427 5.76878 5.76878 5.00427 6.74179 5.00427C7.7148 5.00427 8.47931 5.76878 8.47931 6.74179C8.47931 7.7148 7.7148 8.47931 6.74179 8.47931ZM9.66082 11.4678C9.38282 11.1898 8.96581 11.1898 8.68781 11.4678L6.74179 13.4139L4.79577 11.4678C4.51777 11.1898 4.10076 11.1898 3.82276 11.4678C3.54476 11.7458 3.54476 12.1628 3.82276 12.4408L5.76878 14.3869L3.82276 16.3329C3.54476 16.6109 3.54476 17.0279 3.82276 17.3059C4.10076 17.5839 4.51777 17.5839 4.79577 17.3059L6.74179 15.3599L8.68781 17.3059C8.96581 17.5839 9.38282 17.5839 9.66082 17.3059C9.93882 17.0279 9.93882 16.6109 9.66082 16.3329L7.7148 14.3869L9.66082 12.4408C9.93882 12.1628 9.93882 11.7458 9.66082 11.4678ZM16.8194 11.2593H11.9543C11.5373 11.2593 11.2593 11.5373 11.2593 11.9543V16.8194C11.2593 17.2364 11.5373 17.5144 11.9543 17.5144H16.8194C17.2364 17.5144 17.5144 17.2364 17.5144 16.8194V11.9543C17.5144 11.5373 17.2364 11.2593 16.8194 11.2593ZM16.1244 16.1244H12.6493V12.6493H16.1244V16.1244Z"
      fill="white"
    />
  </svg>
</template>
