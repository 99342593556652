<template>
  <svg
    width="81"
    height="50"
    viewBox="0 0 81 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 48.8672C40.1577 54.3541 70.7324 38.5794 81 30.0062L79.8592 4.2866C68.831 6.00124 44.9493 7.54442 37.6479 0C38.7887 30.8635 7.98592 46.2953 0 48.8672Z"
      fill="currentColor"
    />
  </svg>
</template>
