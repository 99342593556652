import { Axios } from "axios";
import { GetInterestsResponse } from "./dtos/response/get-interests.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async getInterests(): Promise<GetInterestsResponse> {
    const res = await $axios.get(`${apiUrl}/interests`);

    return res.data;
  },
});
