import { InjectionKey } from "vue";
import {
  CategoryApi,
  InterestsApi,
  UserApi,
  StartConditionApi,
  IdeaApi,
  AdditionalAttemptsSourceApi,
} from "@/types";

export const UserApiKey: InjectionKey<UserApi> = Symbol("UserApi");

export const InterestsApiKey: InjectionKey<InterestsApi> =
  Symbol("InterestsApi");

export const CategoryApiKey: InjectionKey<CategoryApi> = Symbol("CategoryApi");

export const StartConditionApiKey: InjectionKey<StartConditionApi> =
  Symbol("StartConditionApi");

export const IdeaApiKey: InjectionKey<IdeaApi> = Symbol("IdeaApi");

export const AdditionalAttemptsSourceKey: InjectionKey<AdditionalAttemptsSourceApi> =
  Symbol("AdditionalAttemptsSourceApi");
