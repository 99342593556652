import { App } from "vue";

import createAdditionalAttemptsSourceRepository from "@/api/additional-attempts-source/additional-attempts-source.repository";
import { AdditionalAttemptsSourceKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createAdditionalAttemptsSourceRepository(
      globalProperties.$axios,
    );
    app.provide(
      AdditionalAttemptsSourceKey,
      repoWithAxios(globalProperties.$apiUrl),
    );
  },
};
