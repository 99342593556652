import { Axios } from "axios";
import { GenerateIdeaRequest } from "./dtos/request/generate-idea.request.dto";

import { GetPaginatedIdeasRequest } from "./dtos/request/get-paginated-ideas.request.dto";
import { GeUserIdeasRequest } from "./dtos/request/get-user-ideas.request.dto";
import { RateIdeaRequest } from "./dtos/request/rate-idea.request.dto";
import { GenerateIdeaResponse } from "./dtos/response/generate-idea.response.dto";
import { GetPaginatedIdeasResponse } from "./dtos/response/get-paginated-ideas.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async getPaginatedIdeas(
    payload: GetPaginatedIdeasRequest,
  ): Promise<GetPaginatedIdeasResponse> {
    let queryString = `p=${payload.page}`;

    if (payload.perPage) {
      queryString += `&pp=${payload.perPage}`;
    }

    if (payload.interest) {
      queryString += `&i=${payload.interest}`;
    }

    if (payload.category) {
      queryString += `&c=${payload.category}`;
    }

    if (payload.userLikes) {
      queryString += `&l=${payload.userLikes}`;
    }

    const res = await $axios.get(`${apiUrl}/ideas?${queryString}`);

    return res.data;
  },

  async getUserIdeas(
    payload: GeUserIdeasRequest,
  ): Promise<GetPaginatedIdeasResponse> {
    const token = localStorage.getItem("token");

    let queryString = `p=${payload.page}`;

    if (payload.perPage) {
      queryString += `&pp=${payload.perPage}`;
    }

    const res = await $axios.get(`${apiUrl}/users/ideas?${queryString}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res.data;
  },

  async generateIdea(
    payload: GenerateIdeaRequest,
  ): Promise<GenerateIdeaResponse> {
    const token = localStorage.getItem("token");

    const body = {
      interest: payload.interest,
      category: payload.category,
      condition: payload.condition,
    };
    const headers = { Authorization: `Bearer ${token}` };

    const res = await $axios.post(`${apiUrl}/ideas/generate`, body, {
      headers,
    });

    return res.data;
  },

  async rateIdea(payload: RateIdeaRequest): Promise<string> {
    const token = localStorage.getItem("token");

    const body = {
      ideaId: payload.ideaId,
      isLiked: payload.isLiked,
    };
    const headers = { Authorization: `Bearer ${token}` };

    const res = await $axios.post(`${apiUrl}/ideas/rate`, body, {
      headers,
    });

    return res.data;
  },
});
