import { App } from "vue";

import createCategoryRepository from "@/api/category/category.repository";
import { CategoryApiKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createCategoryRepository(globalProperties.$axios);
    app.provide(CategoryApiKey, repoWithAxios(globalProperties.$apiUrl));
  },
};
