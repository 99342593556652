<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0044 9.97121L18.0748 2.90051C18.1821 2.79686 18.2677 2.67288 18.3266 2.5358C18.3855 2.39872 18.4165 2.25128 18.4178 2.10209C18.4191 1.95291 18.3907 1.80495 18.3342 1.66687C18.2777 1.52879 18.1942 1.40334 18.0887 1.29784C17.9832 1.19234 17.8578 1.10891 17.7197 1.05242C17.5816 0.995924 17.4337 0.967496 17.2845 0.968792C17.1353 0.970089 16.9879 1.00108 16.8508 1.05997C16.7137 1.11886 16.5897 1.20445 16.4861 1.31177L9.41537 8.38218L2.34495 1.31177C2.13347 1.10493 1.84895 0.989836 1.55314 0.991465C1.25733 0.993095 0.974099 1.11131 0.764909 1.32047C0.555718 1.52962 0.437449 1.81283 0.435768 2.10864C0.434086 2.40445 0.549128 2.68899 0.755928 2.90051L7.82663 9.97121L0.755928 17.0419C0.649686 17.1458 0.565112 17.2698 0.507103 17.4066C0.449095 17.5434 0.418804 17.6904 0.417986 17.839C0.417167 17.9876 0.445837 18.1349 0.502335 18.2723C0.558833 18.4098 0.642036 18.5347 0.747128 18.6397C0.85222 18.7448 0.977112 18.828 1.11457 18.8845C1.25203 18.9409 1.39933 18.9696 1.54794 18.9687C1.69654 18.9679 1.84351 18.9376 1.98031 18.8795C2.11712 18.8215 2.24106 18.7369 2.34495 18.6306L9.41537 11.5602L16.4861 18.6306C16.7054 18.85 16.9931 18.9599 17.2804 18.9599C17.5678 18.9599 17.8557 18.85 18.0748 18.6306C18.2854 18.4199 18.4038 18.1342 18.4038 17.8363C18.4038 17.5383 18.2854 17.2526 18.0748 17.0419L11.0044 9.97121Z"
      fill="#02B1BA"
    />
  </svg>
</template>
