<template>
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1633 44.7135H11.1606C5.1098 44.7135 0.15918 39.7629 0.15918 33.7121V11.7094C0.15918 5.65863 5.1098 0.708008 11.1606 0.708008H33.1633C39.2141 0.708008 44.1647 5.65863 44.1647 11.7094V33.7121C44.1647 39.7629 39.2141 44.7135 33.1633 44.7135Z"
      fill="#2486F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.0627 33.3444C30.9626 29.6773 28.0289 26.927 23.995 26.3769V33.3444H23.4449C12.9936 33.3444 6.94288 26.1936 6.57617 14.092H11.8935C12.0769 22.8931 15.9273 26.7436 19.0444 27.477V14.092H23.995V21.793C27.1121 21.4263 30.2291 17.9425 31.3293 14.092H36.2799C35.3631 18.8593 32.0627 22.3431 29.4957 23.6266C31.8793 24.7267 35.9132 27.8438 37.38 33.3444H32.0627Z"
      fill="white"
    />
  </svg>
</template>
