<template>
  <svg
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56229 1.52453L8.51323 8.47547L15.4642 1.52453"
      stroke="currentColor"
      stroke-width="1.73774"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
