import { Axios } from "axios";
import { UseAdditionalAttemptsSourceRequest } from "./dtos/request/use-additional-attempts-source.request.dto";
import { UseAdditionalAttemptsSourceResponse } from "./dtos/response/use-additional-attempts-source.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async useSource(
    payload: UseAdditionalAttemptsSourceRequest,
  ): Promise<UseAdditionalAttemptsSourceResponse> {
    const token = localStorage.getItem("token");

    const body = {};
    const headers = { Authorization: `Bearer ${token}` };

    const res = await $axios.post(
      `${apiUrl}/additional-attempts-sources/${payload.sourceType}`,
      body,
      {
        headers,
      },
    );

    return res.data;
  },
});
