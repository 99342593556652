import { App } from "vue";

import createInterestsRepository from "@/api/interests/interests.repository";
import { InterestsApiKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createInterestsRepository(globalProperties.$axios);
    app.provide(InterestsApiKey, repoWithAxios(globalProperties.$apiUrl));
  },
};
