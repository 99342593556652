<template>
  <div
    class="bg-[white] border-t-[1px] border-[#F4F6F9] lg:border-none lg:bg-[#F9FBFD]"
  >
    <!-- Desktop -->
    <div class="hidden lg:block cm-content-w pt-[26px] pb-[19px] px-[16px]">
      <div
        class="flex w-full max-w-[1176px] mx-auto pl-[44px] border-2 border-subtle rounded-2xl overflow-hidden"
      >
        <!-- Левый текст с кнопкой -->
        <div class="py-[20px]">
          <p class="font-bold">Вдохновляйся идеями и запускай бизнес</p>
          <p class="font-medium">
            Финансово поддержим твою идею на сайте <br />
            Конструкториума на основе отбора фондом МПИ <br />
            <a
              href="https://constructorium.ru/?utm_source=kai&utm_medium=banner_lk"
              target="_blank"
              class="mt-2 inline-block px-[25px] border border-primary text-white bg-primary rounded-[45px] font-bold hover:text-primary hover:bg-white transition"
            >
              перейти на сайт
            </a>
          </p>
        </div>

        <!-- Ряд значков -->
        <div class="grow ml-5 py-[50px]">
          <div class="relative mt-2">
            <img
              class="absolute top-2"
              src="@/assets/img/ad-banner/arrow.png"
              alt="arrow"
            />
            <div class="absolute lg:left-6 xl:left-14 -top-1">
              <img
                class="inline-block w-10 lg:mt-1 xl:mt-2 lg:mr-1 xl:mr-6"
                src="@/assets/img/ad-banner/rocket.png"
                alt="rocket"
              />
              <img
                class="inline-block w-10 xl:mt-0 lg:mr-1 xl:mr-6"
                src="@/assets/img/ad-banner/ruble-money-sack.png"
                alt="sack"
              />
              <img
                class="inline-block w-10 xl:mt-1 lg:mr-1 xl:mr-6"
                src="@/assets/img/ad-banner/skewed-logo.png"
                alt="logo"
              />
              <img
                class="inline-block w-10 lg:mt-1 xl:mt-1"
                src="@/assets/img/ad-banner/idea.png"
                alt="idea"
              />
            </div>
          </div>
        </div>

        <!-- Правая часть с бизнесменом и фоном -->
        <div class="relative grow">
          <img
            class="absolute right-0 lg:bottom-10 xl:bottom-3 z-10"
            src="@/assets/img/ad-banner/businessman-thinking-ideas.png"
            alt="businessman"
          />
          <img
            class="absolute right-0"
            src="@/assets/img/ad-banner/group.png"
            alt="group"
          />
        </div>
      </div>
    </div>
    <!-- Desktop -->

    <!-- Mobile -->
    <div
      class="block lg:hidden w-full max-w-[1208px] px-[16px] cm-content-w pt-[26px]"
    >
      <div class="relative flex justify-between">
        <div class="pb-[30px] sm:pt-[41px] sm:pb-[58px] mr-[100px]">
          <p class="font-bold text-[15px] sm:text-[20px]">
            Вдохновляйся идеями и запускай бизнес!
          </p>
          <p class="font-medium text-[15px] sm:text-[20px]">
            Финансово поддержим твою идею на сайте <br />
            Конструкториума на основе <br />
            отбора фондом МПИ
          </p>
          <a
            href="https://constructorium.ru/?utm_source=kai&utm_medium=banner_lk"
            target="_blank"
            class="flex sm:text-[20px] px-[25px] py-0.5 border border-primary mt-[20px] w-[fit-content] text-[20px] text-white bg-primary rounded-[45px] font-bold hover:text-primary hover:bg-white transition"
          >
            Перейти на сайт
          </a>
        </div>
        <img
          class="rotate-90 w-full absolute height-[min-content] max-w-[230px] right-[-60px] sm:max-w-[230px] top-[45px] sm:top-[60px] sm:right-[-45px]"
          src="@/assets/img/ad-banner/businessman-thinking-ideas.png"
          alt="businessman"
        />
      </div>
    </div>

    <!-- Mobile -->
  </div>
</template>

<script setup lang="ts"></script>
