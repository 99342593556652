import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "@/plugins/axios";
import userRepoPlugin from "@/plugins/api/user.repository.plugin";
import interestsRepoPlugin from "@/plugins/api/interests.repository.plugin";
import categoryRepoPlugin from "@/plugins/api/category.repository.plugin";
import startConditionRepoPlugin from "@/plugins/api/start-condition.plugin";
import ideaRepoPlugin from "@/plugins/api/idea.repository.plugin";
import additionalAttemptsSourceRepoPlugin from "@/plugins/api/additional-attempts-source.repository.plugin";
import "./assets/css/main.scss";
import "./assets/css/circuit.scss";
import VueYandexMetrika from "vue3-yandex-metrika";

const app = createApp(App);

app.config.globalProperties.$apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3033"
    : "https://api.ai.constructorium.ru";

app.use(store);

app.use(router);
app.use(axios);

app.use(userRepoPlugin);
app.use(interestsRepoPlugin);
app.use(categoryRepoPlugin);
app.use(startConditionRepoPlugin);
app.use(ideaRepoPlugin);
app.use(additionalAttemptsSourceRepoPlugin);

app.use(VueYandexMetrika, {
  id: 85180126,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    webvisor: true,
  },
});

app.mount("#app");
