import { App } from "vue";

import createIdeaRepository from "@/api/idea/idea.repository";
import { IdeaApiKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createIdeaRepository(globalProperties.$axios);
    app.provide(IdeaApiKey, repoWithAxios(globalProperties.$apiUrl));
  },
};
