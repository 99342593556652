import { App } from "vue";

import createStartConditionsRepository from "@/api/start-condition/start-condition.repository";
import { StartConditionApiKey } from "@/symbols";

export default {
  install: (app: App) => {
    const globalProperties = app.config.globalProperties;
    const repoWithAxios = createStartConditionsRepository(
      globalProperties.$axios,
    );
    app.provide(StartConditionApiKey, repoWithAxios(globalProperties.$apiUrl));
  },
};
