<template>
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.87283 9.13828C1.87283 8.77016 1.89822 8.42108 1.94899 8.09105C2.01246 7.76101 2.15209 7.45001 2.36789 7.15805C2.58368 6.8534 2.92007 6.58048 3.37705 6.3393C3.51668 6.26314 3.71343 6.16159 3.96731 6.03465C4.23388 5.90771 4.50045 5.75539 4.76702 5.57767C5.03359 5.39996 5.25573 5.20321 5.43344 4.98741C5.62385 4.75892 5.71905 4.51139 5.71905 4.24482C5.71905 4.05442 5.66193 3.8894 5.54769 3.74977C5.44614 3.59744 5.3065 3.4832 5.12879 3.40703C4.96377 3.33087 4.77971 3.29279 4.57661 3.29279C4.27196 3.29279 4.01808 3.36895 3.81498 3.52128C3.62458 3.66091 3.4659 3.82593 3.33896 4.01634C3.22472 4.19405 3.11682 4.34637 3.01527 4.47331L0.711349 2.83581C0.927143 2.31537 1.23179 1.87743 1.6253 1.522C2.01881 1.15388 2.47579 0.874621 2.99623 0.684214C3.52937 0.481113 4.10059 0.379562 4.7099 0.379562C5.19226 0.379562 5.67462 0.449378 6.15699 0.589011C6.65205 0.728642 7.10268 0.950783 7.50888 1.25543C7.91508 1.56009 8.23877 1.95994 8.47995 2.455C8.73383 2.95006 8.86077 3.55301 8.86077 4.26386C8.86077 4.56852 8.83538 4.86047 8.7846 5.13974C8.73383 5.419 8.64497 5.68557 8.51804 5.93945C8.40379 6.18063 8.24512 6.41546 8.04202 6.64395C7.85161 6.85975 7.61678 7.06285 7.33751 7.25325C6.96939 7.50713 6.58858 7.72292 6.19507 7.90064C5.81426 8.07835 5.49056 8.26241 5.22399 8.45282C4.95742 8.63053 4.82414 8.85902 4.82414 9.13828H1.87283ZM1.92995 14.0698V10.9471H4.88126V14.0698H1.92995Z"
      fill="white"
    />
  </svg>
</template>
