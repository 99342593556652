<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="11" cy="10.7179" rx="11" ry="10.7179" fill="#007B82" />
    <path
      d="M16.7448 8.08114H13.1238V5.34652C13.1238 4.34109 12.3118 3.52344 11.3133 3.52344H9.95541C9.83536 3.52344 9.72024 3.57146 9.63535 3.65693C9.55047 3.7424 9.50278 3.85833 9.50278 3.97921V6.13682L7.75203 9.22239C7.71265 9.29111 7.69203 9.36911 7.69229 9.44845V15.8292C7.69229 15.9501 7.73997 16.066 7.82486 16.1515C7.90974 16.237 8.02487 16.285 8.14491 16.285H15.4095C15.7597 16.2849 16.1023 16.1825 16.3958 15.9903C16.6894 15.7981 16.9213 15.5243 17.0634 15.2021L18.5154 11.9123C18.5417 11.8542 18.5553 11.7911 18.5553 11.7273V9.90422C18.5553 8.89879 17.7433 8.08114 16.7448 8.08114ZM4.07129 9.44845V15.8292C4.07129 15.9501 4.11898 16.066 4.20386 16.1515C4.28874 16.237 4.40387 16.285 4.52391 16.285H6.78704V8.99268H4.52391C4.40387 8.99268 4.28874 9.0407 4.20386 9.12617C4.11898 9.21165 4.07129 9.32757 4.07129 9.44845Z"
      fill="white"
    />
  </svg>
</template>
