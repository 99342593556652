import { Axios } from "axios";

import { GetStartConditionsResponse } from "./dtos/response/get-start-conditions.response.dto";

export default ($axios: Axios) => (apiUrl: string) => ({
  async getStartConditions(): Promise<GetStartConditionsResponse> {
    const res = await $axios.get(`${apiUrl}/start-conditions`);

    return res.data;
  },
});
